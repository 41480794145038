<template>
  <Auth
    class="custom-inputs"
    title="Create new password"
    description="Enter new password to login to your account"
  >
    <ValidationObserver @submit="setUserNewPasswordHandler">
      <div class="mt-5">
        <InputBox
          v-model.number="otp"
          type="text"
          pattern="[0-9]+"
          title="OTP"
          :hide-title="true"
          name="OTP"
          placeholder="Enter OTP"
          class="border-none"
          rules="required"
        />
        <InputBox
          v-model="password"
          title="Password"
          :hide-title="true"
          name="Password"
          type="password"
          placeholder="Password"
          vid="confirmation"
          class="border-none"
          autocomplete="new-password"
          rules="min:8|required"
        />
      </div>
      <div>
        <InputBox
          v-model="confirmPassword"
          v-model:confirm-password="password"
          type="password"
          title="CONF_PASS"
          :hide-title="true"
          name="CONF_PASS"
          placeholder="Confirm Password"
          class="border-none"
          autocomplete="new-password"
          rules="required|confirmed:@Password"
        />
      </div>
      <button
        :disabled="isLoading"
        class="focus:outline-none focus:shadow-md mt-6 mb-20 w-full text-base text-white font-roboto h-12 flex justify-center items-center primary-button"
      >
        <span v-if="isLoading">
          <Loader />
        </span>
        <span v-else class="text-base text-white text-center tracking-wide">Create Password</span>
      </button>
    </ValidationObserver>
  </Auth>
</template>

<script>
import Auth from '@layouts/auth.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { mapActions } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'

export default {
  components: {
    Auth,
    InputBox,
    Loader,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      password: '',
      confirmPassword: '',
      otp: '',
      email: '',
      subDomain: '',
    }
  },
  mounted() {
    this.OTPHandler()
  },
  methods: {
    setUserNewPasswordHandler() {
      this.isLoading = true
      let payload = {
        password: this.password,
        otp: this.otp,
        email: this.email,
        sub_domain: this.subDomain,
      }
      this.setupUserPassword(payload)
        .then(() => {
          this.isLoading = false
          this.$router?.push({ name: 'login' })
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    OTPHandler() {
      let otp = this.$route.query.otp
      let email = this.$route.query.email
      let subDomain = this.$route.query.sub_domain
      if (otp) this.otp = otp
      if (email) this.email = email
      if (subDomain) this.subDomain = subDomain
    },
    ...mapActions('auth', ['setupUserPassword']),
  },
}
</script>

<style lang="scss" module>
@import '@design';
</style>

<style lang="scss">
@import '@src/router/views/auth/auth.scss';
</style>
